
import { defineComponent, watch, ref } from 'vue';
import { useRoute } from 'vue-router';
import { useAdvertisement } from '@/composables/api';

export default defineComponent({
  setup() {
    const advertisementsId = useRoute().params.id as string;
    const page = useRoute().query.page;
    const advertisement = ref();

    const { data, dataUpdatedAt } = useAdvertisement({ advertisementsId });

    watch(dataUpdatedAt, () => {
      advertisement.value = data.value.data;
    });

    return {
      page,
      advertisement
    };
  }
});
